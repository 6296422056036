.shared-button-link {
  display: flex;
  background-color: #fafafa;
  padding: 10px;
  border-radius: 6px;
  margin: 6px 10px;

  .shared-button-link_icon {
    position: relative;
    display: grid;
    place-content: center;
    padding: 5px;
    font-size: 1.6rem;
  }

  .shared-button-link_content {
    // padding: 12px 12px 12px 8px;
    display: grid;
    place-content: center;
    font-size: 1rem;
  }
}

.shared-button-link:hover {
  color: $primary-color !important;
}

.maintenanceScheduleIndexActions {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;

  .back-icon {
    color: black;
    cursor: pointer;
    margin-right: 15px;
  }

  .vehicle-name {
    font-weight: 600;
    color: $secondary-color;
    font-size: 1.5rem;
    text-align: left;
    line-height: normal !important;
    margin-top: 20px;
  }

  .vehicle-vin {
    font-size: 0.9rem;
    margin-top: -20px;
  }

  .settingsButton {
    display: flex;
    padding: 10px;
    align-items: center;
    gap: 10px;
    color: $secondary-color;
  }
}

.maintenance-schedules-container {
  background-position: center;
  background-repeat: no-repeat;
  // height: 80vh;
  height: 100%;
  min-height: 90vh;
  width: 100%;
  max-width: 100%;
  background-size: cover;
  text-align: left;

  .ui.column.grid > [class*="seven wide computer"].column,
  .ui.grid > .column.row > [class*="seven wide computer"].column,
  .ui.grid > .row > [class*="seven wide computer"].column,
  .ui.grid > [class*="seven wide computer"].column {
    width: 50.75% !important;
  }

  .ui.column.grid > [class*="six wide computer"].column,
  .ui.grid > .column.row > [class*="six wide computer"].column,
  .ui.grid > .row > [class*="six wide computer"].column,
  .ui.grid > [class*="six wide computer"].column {
    width: 45.5% !important;
  }

  .ui.form .field > label {
    margin: 8px 8px !important;
    color: #747373 !important;
    font-weight: 700 !important;
    position: absolute !important;
    z-index: 999;
    text-transform: none !important;
  }

  .ui.form .field .ui.input,
  .ui.form .fields .field .ui.input,
  .ui.form .wide.field .ui.input {
    height: 60px;
  }

  .ui.form .field .ui.input input,
  .ui.form .fields .field .ui.input input {
    width: auto;
    border-radius: 6px !important;
    padding: 16px 22px 0px !important;
  }

  .ui.form input:not([type]) {
    padding: 20px 10px 0px !important;
  }

  .ui.icon.input > i.icon {
    margin: 8px 8px 0px;
  }

  .hide-bg {
    mix-blend-mode: multiply;
    filter: contrast(1);
    margin: 0% 5% !important;
    width: 400px;
  }

  .default_image {
    margin: 15% 20% !important;
    width: 500px !important;
  }

  .ui.grid > .row > .column > img,
  .ui.grid > .row > img {
    max-width: 80%;
  }

  .maintenanceRow {
    display: inline-block;
    width: 100%;
    background-color: #fff;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid #efefef;
    margin: 10px 0px;
    border-radius: 6px;
  }

  .maintenanceRowEmpty {
    display: inline-block;
    width: 100%;
    background-color: #fff;
    vertical-align: middle;

    border: 1px solid #efefef;
    margin: 10px 0px;
    border-radius: 6px;
    padding: 15px;
    display: flex;
    margin: 10px 0px;
    width: 95%;
    cursor: default !important;
    //  text-align: center;
    height: 40px;
    align-items: center;
    // justify-content: center;
    font-size: 14px;
  }

  .maintenanceScheduleHeader {
    color: #000000 !important;
  }

  .maintenanceRowLink {
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    cursor: pointer;
    color: #666;
  }

  .dueStatusTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 17px;
    color: #000000;
  }

  .maintenanceScheduleSection {
    width: 100%;
  }

  .allServicesCountAndStatusContainer {
    display: block;
    margin: 42px 8px 18px !important;
  }

  .bookNowLabel {
    padding: 5px 15px 5px 15px;
    border-radius: 6px;
    margin-left: 50px;
    background-color: $primary-color-light;
    color: $secondary-color;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
  }

  .bookNowLabel-color {
    color: $primary-theme-text-color;
  }

  .servicesCount {
    color: #f4f7f5;
    display: initial;
    padding: 2px 8px;
    border-radius: 20px;
  }

  .makeModelHeader {
    margin-top: 0px !important;
    font-weight: 600;
    color: #000000 !important;
    overflow: hidden;
    white-space: nowrap;

    // width: 250px;

    //  h1:hover {
    //     overflow:visible;
    //  }
  }

  .vehicleYearLabel {
    border: 1px solid lightgrey;
    margin: 0px 20px;
    height: 38px;
    padding: 6px 18px 12px;
    font-size: 24px;
  }

  .vehicleHeader {
    display: inline-flex;
    text-align: center;
  }

  .allServicesName {
    color: #000000;
    font-weight: 700;
  }

  .scrolling {
    flex-wrap: nowrap !important;
    overflow-y: scroll !important;
    -webkit-overflow-scrolling: touch !important;
    overflow-scrolling: touch !important;
  }

  .scrollableSegment {
    overflow: auto;
    max-height: 60vh;
    background: transparent;
    border: 0px;
    box-shadow: none;
    padding-left: 0px;
  }

  .markAllCompletedRadio {
    padding: 4px 8px;
    margin: 0px 2px 0px;
    display: flex;
    align-items: center;
  }

  .mr-schedule-vehicle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // margin-left: 20%;
    margin: auto;
  }

  .allServicesName {
    float: left;
    padding-top: 21px;
    padding-left: 10px;
  }

  .allServicesArrow {
    float: right;
    width: 10%;
    padding: 20px 0 15px 10px;
  }

  .arrowRight {
    float: right;
    width: 27px;
    margin: 0;
    padding-right: 20px;
  }

  .spinnerContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $secondary-color;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 9999;
  }

  .spinnerText {
    display: block;
    font-size: 20;
    color: #ffffff;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-top: 100px;
  }

  .spinnerTransparentContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
  }

  .spinnerTextBlack {
    display: block;
    background-color: #fff;
    font-size: 20;
    color: #000;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-top: 100px;
    border-radius: 10px;
    box-shadow: 2px 2px 5px #ccc;
    padding: 20px 40px 20px 40px;
  }

  .spinnerText img {
    display: inline-block;
    clear: both;
    max-width: 300px;
  }
}

.maintenance-schedules-container1 {
  background-position: center;
  background-repeat: no-repeat;
  // height: 80vh;
  height: 100%;
  min-height: 100vh;
  width: auto;
  max-width: 100%;
  background-size: cover;
  text-align: left;
  margin: 1rem;

  .ui.form .field > label {
    margin: 10px 2px 5px 22px !important;
    color: #747373 !important;
    font-weight: 700 !important;
    position: absolute !important;
    z-index: 999;
    text-transform: none !important;
  }

  .ui.form .field .ui.input,
  .ui.form .fields .field .ui.input,
  .ui.form .wide.field .ui.input {
    height: 60px;
  }

  .ui.form .field .ui.input input,
  .ui.form .fields .field .ui.input input {
    width: auto;
    border-radius: 6px !important;
    padding: 16px 22px 0px !important;
  }

  .ui.form input:not([type]) {
    padding: 20px 10px 0px !important;
  }

  .ui.icon.input > i.icon {
    margin: 8px 8px 0px;
  }

  .hide-bg {
    mix-blend-mode: multiply;
    filter: contrast(1);
  }

  .ui.grid > .row > .column > img,
  .ui.grid > .row > img {
    max-width: 80%;
  }

  .maintenanceRow {
    display: inline-block;
    width: 100%;
    background-color: #fff;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid #efefef;
    margin: 10px 0px;
    border-radius: 6px;
  }

  .maintenanceRowEmpty {
    display: inline-block;
    width: 100%;
    background-color: #fff;
    vertical-align: middle;

    border: 1px solid #efefef;
    margin: 10px 0px;
    border-radius: 6px;
    padding: 15px;
    display: flex;
    margin: 10px 0px;
    width: 95%;
    cursor: default !important;
    //  text-align: center;
    height: 40px;
    align-items: center;
    // justify-content: center;
    font-size: 14px;
  }

  .maintenanceScheduleHeader {
    color: #000000 !important;
  }

  .maintenanceRowLink {
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    cursor: pointer;
    color: #666;
  }

  .dueStatusTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 17px;
    color: #000000;
  }

  .maintenanceScheduleSection {
    width: 100%;
  }

  .allServicesCountAndStatusContainer {
    display: block;
    margin: 42px 8px 18px !important;
  }

  .servicesCount {
    color: #f4f7f5;
    display: initial;
    padding: 2px 8px;
    border-radius: 20px;
  }

  .makeModelHeader {
    margin-top: 0px !important;
    font-weight: 600;
    color: #000000 !important;
  }

  .vehicleYearLabel {
    border: 1px solid lightgrey;
    margin: 0px 20px;
    height: 22px;
    padding: 6px 18px 12px;
    font-size: 24px;
  }

  .vehicleHeader {
    display: inline-flex;
    text-align: center;
  }

  .bookNowLabel {
    background-color: $primary-color-light;
    color: $secondary-color;
    padding: 10px 5px 10px 5px;
    border-radius: 10px;
  }

  .scrolling {
    flex-wrap: nowrap !important;
    overflow-y: scroll !important;
    -webkit-overflow-scrolling: touch !important;
    overflow-scrolling: touch !important;
  }

  .scrollableSegment {
    overflow: auto;
    max-height: 65vh;
    background: transparent;
    border: 0px;
    box-shadow: none;
    padding-left: 0px;
  }

  .markAllCompletedRadio {
    padding: 4px 8px;
    margin: 0px 2px 0px;
    display: flex;
    align-items: center;
  }

  .allServicesName {
    float: left;
    padding-top: 21px;
    padding-left: 10px;
  }

  .allServicesArrow {
    float: right;
    width: 10%;
    padding: 20px 0 15px 10px;
  }

  .arrowRight {
    float: right;
    width: 27px;
    margin: 0;
    padding-right: 20px;
  }

  .mr-schedule-vehicle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 20%;

    .vehicle-name {
      font-weight: 600;
      color: $secondary-color;
      font-size: 1.4rem;
      text-align: center;
    }

    .vehicle-vin {
      margin: 30px;
    }
  }
}

.maintenance-schedule-header {
  font-size: 32px !important;
}

.maintenance-schedule-car-container {
  display: flex;
  justify-content: center;
  width: 400px;

  .vehicleYearLabel {
    border: 1px solid lightgrey;
    margin: 0px 20px;
    height: 22px;
    padding: 6px 18px 12px;
    font-size: 24px;
  }

  .hide-bg {
    mix-blend-mode: multiply;
    filter: contrast(1);
    max-width: 85%;
  }

  .vehicle-make-year {
    display: flex;
    flex-direction: row;
  }

  .makeModelHeader {
    margin-top: 0px !important;
    font-weight: 600;
    color: #000000 !important;
    font-size: 1.5rem !important;
  }

  .makeModelHeader1 {
    margin-top: 0px !important;
    font-weight: 600;
    color: #000000 !important;
    font-size: 1.5rem !important;
  }

  .vehicleHeader {
    display: inline-flex;
    text-align: center;
  }
}

.maintenance-schedule-backbround {
  background-position: center;
  background-repeat: no-repeat;
  // height: 80vh;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  max-width: 100%;
  background-size: cover;
  text-align: left;
}

.maintenance-schedule-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  align-self: center;
  // width: auto;
  height: 70vh;
  padding: 0px !important;
  margin-left: 1%;
}

.maintenanceScheduleHeader {
  font-size: 32px !important;
  color: black !important;
}

.maintenance-schedule-child-container {
  // display: flex;
  // position: relative;
  height: 90vh;
}

.manufacturer-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 5px 5px 5px;

  .ui.checkbox input:checked ~ label:after {
    background-color: $manufacturer-title-checkbox-color !important;
    margin: 1px 1px 1px 1px !important;
    color: white;
    border-color: $manufacturer-title-checkbox-color !important;
    border-radius: 2px;
    font-size: 12px;
  }
}

.miles-header-container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
}

.icon-container {
  background-color: #fff;
  border-radius: 50%;
  padding: 4px 2px 4px 2px;
  box-shadow: 2px 2px 5px #ccc;
  cursor: pointer;
}

.arrow-icon {
  margin: 0 0.25rem 0 0.25rem !important;
}

.services-header {
  display: flex;
  justify-content: center;
}

.services-sub-header {
  display: flex;
  justify-content: flex-start;
  color: $secondary-color !important;
  cursor: pointer;
}

.services-sub-header1 {
  display: flex;
  justify-content: flex-end;
  color: #666666 !important;
  cursor: pointer;
}

.done-color {
  color: $fleet-list-highlight-text-color !important;
}

.mark-complete-text-color {
  color: $primary-theme-text-color !important;
}

.completed-image {
  height: 20px;
}

.mark-all-completed-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
}

.completed-services-container {
  background-color: #ecfdf5;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 100px;
}

.wrench-icon-container {
  border-radius: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px 0px 10px;
  width: max-content;
  align-content: flex-start;
  flex-wrap: nowrap;
  color: #fff;
  max-height: 2rem;
  margin: 0px 0px 7px 0px;
}

.wrench-icon {
  cursor: pointer;
  color: #fff;
}

.scroll {
  overflow-y: auto;
  height: 50vh;
  width: -webkit-fill-available;
  width: -moz-available;
  padding: 0 20px;

  .extra-padding {
    padding-bottom: 100px;
    margin-top: 30px;
  }
}

.bottom-fixed {
  position: inherit;
  width: -webkit-fill-available;
  width: -moz-available;
  height: 100px;
  flex-shrink: 0;
  border-radius: 0px 0px 8px 8px;
  border: 1px solid #d9d9d9;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;

  .ui.button {
    width: 330px;
  }
}

.bottom-fixed-in-mobile {
  position: fixed !important;
  bottom: 0% !important;
  width: 100% !important;
  right: 0% !important;
  display: none;
  position: relative;

  .bottom-vehicle-container {
    display: none;
  }

  .fixed-content-summary {
    width: -webkit-fill-available;
    width: -moz-available;
    background-color: #fff;
    flex: 1;
    justify-content: center;
    background-color: #fff;
    border-radius: 6px;
    margin-bottom: 20px;
    box-shadow: 2px 2px 10px #ccc;
    text-align: left;
    padding: 20px;
    margin: 15px;
  }
}

.manufacturer-title1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

@media only screen and (max-width: 1024px) {
  .flexbox-component-header {
    // display: none;
  }

  // .scroll {
  //   overflow-y: auto;
  //   height: 48% !important;
  // }
}

@media only screen and (max-width: 950x) {
  .bottom-fixed {
    position: absolute;
    bottom: 2%;
  }

  // .scroll {
  //   overflow-y: auto;
  //   height: 44% !important;
  // }
  .flexbox-component-header {
    font-size: 1.5rem !important;
  }

  .maintenance-schedule-car-container {
    justify-content: center;
    width: 450px;

    .hide-bg {
      mix-blend-mode: multiply;
      filter: contrast(1);
      max-width: 80%;
    }

    .vehicleYearLabel {
      border: 1px solid lightgrey;
      margin: 0px 20px;
      height: 22px;
      padding: 6px 18px 12px;
      font-size: 24px;
    }

    .vehicleHeader {
      display: inline-flex;
      margin-left: -25px !important;
      flex-direction: row;
      align-items: center;
    }

    h2.ui.header {
      font-size: 1.5rem;
      margin: 3px;
    }
  }
}

@media only screen and (max-width: 920px) {
  // .scroll {
  //   overflow-y: auto;
  //   height: 55% !important;
  // }
  .services-header {
    margin: 0px 30px 0px 30px;
  }
}

@media only screen and (max-width: 820px) {
  // .scroll {
  //   overflow-y: auto;
  //   height: 70% !important;
  // }
  .services-header {
    margin: 0px 30px 0px 30px;
  }
}

@media only screen and (max-width: 768px) {
  // .scroll {
  //   overflow-y: auto;
  //   height: 50% !important;
  // }
  .services-header {
    margin: 0px 30px 0px 30px;
  }
}

.mark-completed-checkbox {
  .checkbox.ui.checkbox label:before {
    border-radius: 100px !important;
    color: white !important;
    background: #0a0909;
  }
}

@media all and (max-width: 540px) {
  .bottom-fixed-in-mobile {
    display: block;
  }

  .bottom-fixed {
    display: none;
  }

  // .scroll {
  //   overflow-y: hidden;
  //   height: 90% !important;
  //   padding-bottom: 300px !important;
  //   width: -webkit-fill-available;
  // }
  .scroll-on-phone {
    overflow-y: auto !important;
    -ms-overflow-style: none;
  }

  .scroll-on-phone::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }

  .extra-padding {
    padding-bottom: 100px;
  }
}

@media all and (max-width: 375px) {
  // .scroll {
  //   overflow-y: hidden;
  //   padding-bottom: 300px !important;
  // }
}

.skeleton {
  z-index: 0 !important;
}

@media only screen and (max-width: 1366px) {
  .ui.form .field > label {
    margin: 8px 8px !important;
  }

  .ui.column.grid > [class*="seven wide computer"].column,
  .ui.grid > .column.row > [class*="seven wide computer"].column,
  .ui.grid > .row > [class*="seven wide computer"].column,
  .ui.grid > [class*="seven wide computer"].column {
    width: 50.75% !important;
  }

  .ui.column.grid > [class*="six wide computer"].column,
  .ui.grid > .column.row > [class*="six wide computer"].column,
  .ui.grid > .row > [class*="six wide computer"].column,
  .ui.grid > [class*="six wide computer"].column {
    width: 45.5% !important;
  }
}

@media only screen and (max-width: 1280px) {
  .maintenance-schedules-container {
    .ui.form .fields {
      display: inline-block;
      padding: 0px 12px;
      width: 50%;
    }

    .ui.form:not(.unstackable) [class*="equal width"].fields:not(.unstackable) > .field,
    .ui[class*="equal width"].form:not(.unstackable) .fields > .field {
      width: 100% !important;
      margin: 0 0 1em;
    }
  }
}

@media only screen and (max-width: 1132px) {
  .maintenance-schedules-container {
    .ui.form .fields {
      display: inline-block;
      padding: 0px 10px;
      width: 53%;
    }
  }
}

@media only screen and (max-width: 1080px) {
  .maintenance-schedules-container {
    .ui.form .fields {
      display: flex;
      width: auto;
    }

    .ui[class*="two column"].grid > .column:not(.row),
    .ui[class*="two column"].grid > .row > .column {
      width: 100% !important;
    }

    .scrollableSegment {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .maintenance-schedules-container {
    .ui[class*="two column"].grid > .column:not(.row),
    .ui[class*="two column"].grid > .row > .column {
      width: 100% !important;
    }

    .scrollableSegment {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 770px) {
  .maintenance-schedules-container {
    .ui[class*="two column"].grid > .column:not(.row),
    .ui[class*="two column"].grid > .row > .column {
      width: 100% !important;
    }

    .ui.form .fields {
      display: inline-block;
      padding: 0px 10px;
      width: 50%;
    }

    .ui.form:not(.unstackable) [class*="equal width"].fields:not(.unstackable) > .field,
    .ui[class*="equal width"].form:not(.unstackable) .fields > .field {
      width: 100% !important;
      margin: 0 0 1em;
    }
  }
}

@media only screen and (max-width: 650px) {
  .maintenance-schedules-container .ui.form .fields {
    display: inline-block;
    padding: 0px 12px;
    width: 50%;
  }

  .bottom-fixed {
    justify-content: space-around;

    .ui.button {
      width: 250px;
    }
  }
}

@media only screen and (max-width: 550px) {
  .maintenance-schedules-container .ui.form .fields {
    display: inline-block;
    padding: 0px 5px;
    width: 50%;
  }

  .bottom-fixed {
    justify-content: space-around;

    .ui.button {
      width: 250px;
    }
  }
}

@media only screen and (max-width: 425px) {
  .maintenance-schedules-container .ui.form .fields {
    display: inline-block;
    padding: 0px 5px;
    width: 100%;
  }
}

@media only screen and (max-width: 390px) {
  .maintenance-schedules-container .bookNowLabel {
    margin-left: 15px;
  }
}

.vehicle-name-text-color {
  color: $primary-theme-text-color !important;
}
