.service-daterange {
  display: flex;
  flex-direction: row;

  .service-datepicker {
    display: inline-flex;
    vertical-align: middle;
    line-height: 2.5em;
    margin-right: 1em;

    input {
      padding: 5px;
      margin-left: 0.5em;
      font-size: larger;
    }
  }
}

.size-to-fit {
  label::after {
    background: $checkbox-background-color;
    border-radius: 3px;
    border: 1.5px solid $checkbox-background-color !important;
  }

  input:checked ~ label:after {
    color: $checkbox-checked-color !important;
    border: 1px solid $checkbox-border-color !important;
    font-size: 12px;
  }

  input:focus:checked ~ label:after {
    color: $checkbox-checked-color !important;
    border: 1px solid $checkbox-border-color;
  }
}

.ag-theme-material .ag-checkbox-input-wrapper.ag-checked::after {
  color: $transfer-vehicle-checkbox-background-color !important;
}

.ui.checkbox label:before {
  border: 1.5px solid $checkbox-border-color !important;
}

.checkbox-color {
  color: $transfer-vehicle-checkbox-background-color;
}
