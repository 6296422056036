.sign-in-container {
  flex: 1;
  flex-direction: column;
  text-align: center;
}

.sign-in-form-container {
  margin-top: 100px;
}

.sign-in-header {
  background-color: $primary-color;
  padding: 50px;
}

.sign-in-input {
  @include text-input;
}

.sign-in-input.width {
  width: 460px;
}

.sign-in-input-no-margin {
  @include text-input;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.sign-in-logo {
  height: 208px;
  width: 208px;
}

.sign-in-form {
  margin-top: 20px;
  text-align: left;

  label {
    text-align: start;
    padding: 0 3%;
    font-weight: 700;
    font-size: 12px;
    color: $signin-form-label-color;
  }
}

.mobile-store-button {
  padding-top: 10px;
}

a[target="_blank"]::after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
  margin: 0 3px 0 5px;
}

button.ui.button.button--submit :focus {
  background-color: #3363a0;
  color: white;
  outline: 2px solid #003c88;
}

button.ui.button:focus {
  background-color: #3363a0;
  color: white;
  outline: 2px solid $ui-button-focus-border;
}

.sign-in-submit {
  padding-top: 20px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

div.sign-in-submit-no-margin {
  text-align: left;
  margin-top: 30px !important;
}

.sign-in-tab-pane-container {
  width: 370px;
  border: none !important;
  box-shadow: none !important;
  padding-right: 1em !important;
}

.ui.attached.tabular.menu {
  border-bottom: none;
}

.otp_error {
  color: red;
  display: flex !important;
  line-height: normal;
  padding-left: 15px;
}

@media screen and (max-width: 768px) {
  .sign-in-tab-pane-container {
    padding: 0px;
    margin: 10px 0px 0px 0px;

    .sign-in-submit {
      margin-left: 1em !important;
    }

    input {
      margin-left: 3em !important;
    }

    .sign-in-password {
      margin: 1em;
    }
  }

  .sign-in-input,
  .sign-in-input-no-margin {
    width: 18em;
  }
}

.signup-desc {
  color: $black-text-color;
  font-weight: 400;
  font-size: 16px;
  padding: 3%;
}

.privacy-policy-link-color {
  a {
    color: $dashboard-link-color !important;
  }

  a:hover {
    color: $button-link-hover-color !important;
  }
}
