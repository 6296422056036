@import "variables";

$dt-primary: #e6121a;
$dt-color: #e6121a;
$dt-hovar-color: #b70e15;

$primary-color: $dt-primary;
$primary-color-hover: $dt-hovar-color;
$light-blue: $dt-color;
$primary-button-color: $dt-color;
$primary-button-hover-color: $dt-hovar-color;
$header-text-color: $dt-primary;
$primary-button-color: $dt-color;
$primary-button-hover-color: $dt-hovar-color;
$secondary-button-text-color: #cf202f;
$recharts-primary-color: $dt-color;
$recharts-secondary-color: $dt-color;
$primary-color-light: #fef5cd;

/* ***** Glossary colors: ***** */
$dt-yellow: #ffa10d;
$dt-yellow-hover: #c87a00;
$dt-red: #e6121a;
$dt-red-hover: #b70e15;
$dt-dark-blue: #3483de;
$dt-dark-blue-hover: #1d62b1;
$dt-gray: #667792;
$dt-dark-gray: #515f74;
$dt-black: #121213;
$dt-light-red-color: #fcd9da;
/* **************************** */

$secondary-color: $dt-red;

$primary-button-color: $dt-primary;
$primary-button-hover-color: $dt-hovar-color;

/* Sign in screen background gradient */
$primary-color-gradient-start: $dt-black;
$primary-color-gradient-end: $dt-black;

/* Recharts */
$recharts-primary-color: $dt-primary;
$recharts-secondary-color: $dt-primary;
/* $recharts-neutral-color: no override */
$recharts-negative-color: $dt-red;

/* Sidebar icons */
$icon-color-active: $dt-light-red-color;
$icon-color-inactive: #ffffff;

@font-face {
  font-family: "Pill Gothic";
  src: url("fonts/Pill_Gothic_600mg_Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

$font-family: "Pill Gothic", sans-serif !important;

$font-color: $dt-dark-gray;
$header-text-color: $dt-primary;
$past-due-color: $dt-red;
$due-soon-color: $dt-dark-blue;
/* This is used in many places- not worth renaming the variable name for now */
$light-blue: $dt-gray;
$ui-button-focus-border: #aa183c;
$ui-button-focus-background-color: #aa183c;
$checkbox-background-color: $dt-primary;
$checkbox-checked-color: #fff;
$toggle-checkbox-color: $dt-primary;
$transfer-vehicle-checkbox-background-color: $dt-primary;
$primary-button-focus-text-color: #fff;
$primary-button-focus-color: $ui-button-focus-background-color;
$button-disabled-background-color: $dt-color;
