// Changes the font family
$font-family: $font-family;

@import "../../../../node_modules/react-notifications-component/dist/scss/notification.scss";

//Custom Styles

.notification-container-top-right {
  right: 10px !important;
  width: 450px !important;
}

.rnc__notification-close-mark {
  color: #ffffff !important;
  font-size: 20px !important;
  font-weight: 600;
}

.rnc__notification-close-mark:after {
  content: "\D7";
  position: absolute;
  color: #ffffff;
  font-size: 20px;
  right: 20px;
}

.notification__custom-icon {
  flex-basis: 20%;
  position: relative;
  padding: 8px 8px 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  .fa {
    color: #ffffff;
    font-size: 28px;
  }
}

.notification__custom {
  flex-basis: 80%;
  padding: 12px 12px 12px 8px;
  display: inline-block;
}

.notification__custom--success {
  width: 100%;
  display: flex;
  background-color: $notification-success-background-color;

  .notification__custom-icon {
    border-left: 8px solid darken(#84d65a, 15%);
  }

  .border-unset {
    border-left: unset !important;
    align-items: flex-start !important;
  }

  .notification__custom {
    .rnc__notification-title {
      font-weight: 700;
      font-size: 18px;
      color: $notification-success-text-color;
    }

    .rnc__notification-message {
      font-weight: 500;
      font-size: 14px;
      color: $notification-success-text-color;
    }
  }
}

.notification-border--success {
  border: 1px solid $alert-success-border-color;
}

.notification__custom--default {
  width: 100%;
  display: flex;
  background-color: $notification-default-background-color;

  .notification__custom-icon {
    border-left: 8px solid darken(#eeeefe, 15%);
  }

  .border-unset {
    border-left: unset !important;
    align-items: flex-start !important;
  }

  .notification__custom {
    .rnc__notification-title {
      font-weight: 700;
      font-size: 18px;
      color: $notification-default-text-color;
    }

    .rnc__notification-message {
      font-weight: 500;
      font-size: 14px;
      color: $notification-default-text-color;
    }
  }
}

.notification-border--default {
  border: 1px solid $alert-default-border-color;
}

.notification__custom--danger {
  width: 100%;
  display: flex;
  background-color: $notification-danger-background-color;

  .notification__custom-icon {
    border-left: 8px solid darken(#eb5757, 15%);
  }

  .border-unset {
    border-left: unset !important;
    align-items: flex-start !important;
  }

  .notification__custom {
    .rnc__notification-title {
      font-weight: 700;
      font-size: 18px;
      color: $notification-danger-text-color;
    }

    .rnc__notification-message {
      font-weight: 500;
      font-size: 14px;
      color: $notification-danger-text-color;
    }
  }
}

.notification-border--danger {
  border: 1px solid $alert-error-border-color;
}

.notification__custom--info {
  width: 100%;
  display: flex;
  background-color: $notification-info-background-color;

  .notification__custom-icon {
    border-left: 8px solid darken(#17a2b8, 15%);
  }

  .border-unset {
    border-left: unset !important;
    align-items: flex-start !important;
  }
}

.notification-border--info {
  border: 1px solid $notification-info-border-color;
}

.notification__custom--warning {
  width: 100%;
  display: flex;
  background-color: $notification-warning-background-color;

  .notification__custom-icon {
    border-left: 8px solid darken(#eab000, 15%);
  }

  .border-unset {
    border-left: unset !important;
    align-items: flex-start !important;
  }
}

.notification-border--warning {
  border: 1px solid $alert-warning-border-color;
}

.rnc__notification-close-mark:after {
  color: $notification-close-mark-color;
}
