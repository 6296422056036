.ui.button {
  background-color: $primary-button-color !important;
  color: $primary-text-color !important;
}

.ui.button:disabled {
  background-color: $button-disabled-background-color !important;
  color: $button-disabled-color !important;
  font-weight: 700 !important;
}

.ui.button:hover {
  background-color: $primary-button-hover-color !important;
  color: $primary-text-color !important;
}

.button-text {
  margin-top: -1px;
  /* Or else the text is offcenter dont know why */
}

.grey-button-text-color {
  color: $tertiary-button-text-color;
}

.white-button-text-color {
  color: $secondary-button-text-color;
}

.primary-button-text-color {
  color: $primary-button-text-color;
}

.primary-button-color {
  color: $primary-button-color;
}

.dropdown-disable-option-text {
  color: $primary-theme-text-color !important;
}

.grey-button,
.ui.button.grey-button {
  background-color: $tertiary-button-color !important;
  color: $secondary-button-text-color !important;
  border-color: $secondary-button-text-color !important;
}

.grey-button:hover {
  background-color: $tertiary-button-hover-color;
  border-color: $tertiary-button-hover-color;
}

.orange-link {
  color: $primary-button-color;
}

.orange-button {
  background-color: $orange-button-color;
  border-color: $orange-button-color;
  color: $primary-button-text-color;
}

.orange-button:hover {
  background-color: $orange-button-color-hover;
  border-color: $orange-button-color-hover;
  color: $primary-button-text-color !important;
}

.white-button,
.ui.button.white-button {
  background-color: $secondary-button-color !important;
  color: $secondary-button-text-color !important;
  border-color: $secondary-button-text-color !important;
}

.white-button:hover,
.ui.button.white-button:hover {
  background-color: $secondary-button-hover-color !important;
}

.ui.button.add-vehicle-next-button {
  background-color: $primary-theme-text-color !important;
  color: $modal-text-color !important;
  margin-left: unset !important;
}

.ui.button.add-vehicle-next-button:hover {
  background-color: $primary-theme-text-color-hover !important;
}

.ui.button.add-vehicle-next-button:focus {
  background-color: $primary-theme-text-color-focus !important;
  color: $modal-text-color !important;
}

.add-vehicle-next-width {
  width: 75%;
}

.add-vehicle-next-button-parent {
  text-align: center;
  width: 100%;
}

.add-vehicle-btn-parent {
  display: flex;
  justify-content: center;
}

.button-icon-color {
  margin-top: -10px;
  color: $red !important;
  margin-right: 10px;
}

.ui.button.add-more-btn {
  background-color: $primary-theme-text-color !important;
  color: $modal-text-color !important;
}

.ui.button.add-more-btn:hover {
  background-color: $primary-theme-text-color-hover !important;
  color: $modal-text-color !important;
}

.ui.button.add-more-btn:focus {
  background-color: $primary-theme-text-color-focus !important;
  color: $modal-text-color !important;
}

.reset-filters-btn {
  color: $primary-theme-text-color !important;
  background: $primary-button-color !important;
}

.reset-filters-btn:hover {
  color: $primary-theme-text-color !important;
  background: $primary-button-hover-color !important;
}

.favorite-shop-text-color {
  color: $primary-theme-text-color !important;
}

.btn-margin-right {
  margin-right: 24px !important;
}

.vehicle-icons {
  display: inline !important;
  padding-right: 8px;
  height: 12px;
}

.ui.button.signin-btn-color {
  background: $primary-theme-text-color !important;
  color: $navbar-popup-text-color !important;
}

.ui.button.signin-btn-color:hover {
  background: $primary-theme-text-color-hover !important;
  color: $navbar-popup-text-color !important;
}

.ui.button.signin-btn-color:focus {
  background: $primary-theme-text-color-focus !important;
  color: $navbar-popup-text-color !important;
  outline: unset !important;
}
